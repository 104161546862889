.device-container {
  padding: 0px 66px;
  position: relative;

  &.wrapper-mobilephone-smartphone-16-9,
  &.wrapper-mobilephone-smartphone-9-16,
  &.wrapper-mobiletablet-tablet-4-3,
  &.wrapper-mobiletablet-tablet-3-4,
  &.wrapper-mobiletablet-tablet-16-10,
  &.wrapper-mobiletablet-tablet-10-16,
  &.wrapper-ctv-default,
  &.wrapper-u6hero-default,
  &.wrapper-tcl-default,
  &.wrapper-samsungExtraWide-default,
  &.wrapper-samsungFirstScreen-default,
  &.wrapper-easter-louvre {
    padding: 0px 0px 40% 0px;
  }

  &.wrapper-inarticlemobilephone-smartphone-9-16,
  &.wrapper-inarticlemobiletablet-tablet,
  &.wrapper-unmissablephone-smartphone-9-16,
  &.wrapper-unmissablemobiletablet-tablet,
  &.wrapper-verticalmobilephone-smartphone-9-16,
  &.wrapper-inarticlemobilephone-smartphone-16-9,
  &.wrapper-unmissablephone-smartphone-16-9,
  &.wrapper-verticalmobilephone-smartphone-16-9,
  &.wrapper-verticalmobiletablet-tablet {
    height: 670px;
    padding: 0px;
  }

}

.btn-siu-video-apply {
  width: 80%;
}

.siu-player-wrapper {
  display: flex;
  justify-content: center;
 /* height: 70%;*/

/*  .siu-skin-image {
    background: url('../devices/siu/smart_tv_skin.png') no-repeat;
    background-size: contain;
    background-position: center;
    width: 70%;
  }*/

  .siu-content {
    display: flex;
    justify-content: center;
    position: relative;
    align-items: center;

    .banner-action-area {
      z-index: 1;
      position: absolute;
      height: 84%;
      width: 97%;
      top: 20px;

      .close-btn {
        cursor: pointer;
        position: absolute;
        width: 30px;
        right: -8px;
        top: -4px;
        background-color: black;
        padding: 7px;
        opacity: 0.7;

        &:hover {
          opacity: 1;
        }
      }

      .click-thru-iframe {
        width: 100%;
        height: 100%;
      }
      .siu-video-player {
        background-color: black;
        width: 100%;
        height: 100%;
      }

      .deep-link-box {
        width: 100%;
        height: 100%;
        background-color: #474747;
        font-size: 25pt;
        color: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }


  }
  .siu-content-image {
    position: absolute;
    height: 70%;
    top: 10%;
  }
  .wedge-content-image {
    position: absolute;
    height: 83%;
    top: 3%;
  }

  .home-launcher-content-image {
    position: absolute;
    height: 83%;
    top: 3%;
  }

  .siu-content-ad {
    position: absolute;
    cursor: pointer;

    .siu-banner-image {
      width: 100%;
      height: 100%;
    }

    &.banner-size-300x250 {
      width: 205px;
      height: 170px;
      top: 80px;
      right: 257px;
    }

    &.banner-size-287x157 {
      width: 195px;
      height: 107px;
      top: 80px;
      right: 158px;
    }

    &.banner-size-360x150 {
      width: 244px;
      height: 102px;
      top: 257px;
      right: 217px;
    }

    &.banner-size-728x90 {
      width: 512px;
      height: 64px;
      top: 77px;
      right: 331px;
    }

    &.banner-size-970x90 {
      width: 659px;
      height: 61px;
      top: 80px;
      right: 469px;
    }

    &.banner-size-970x250 {
      width: 679px;
      height: 175px;
      top: 77px;
      right: 467px;
    }

    &.banner-size-360x232 {
       width: 244px;
       height: 157px;
       top: 257px;
       right: 217px;
    }


    &.banner-size-412x212 {
      width: 279px;
      height: 145px;
      top: 255px;
      right: 181px;
    }
    &.banner-size-500x160 {
      width: 341px;
      height: 108px;
      top: 77px;
      right: 82px;
    }
    &.banner-size-860x90 {
      width: 583px;
      height: 61px;
      top: 79px;
      right: 517px;
    }
    &.banner-size-1400x200 {
      width: 950px;
      height: 136px;
      top: 77px;
      right: 111px;
    }

    &.banner-size-wedge {
      height: 83%;
      top: 3%;
      left: 2%;
      overflow: hidden;
      max-width: 190px;
      direction: rtl;

      & .siu-banner-image {
        left: 80%; /* 80% from the visible ad area - from spec - the ad visible area is 446px, and the shift is 357px = 357/446 = 80% */
        height: 72%;
      }

      &.wedge-my-content-open {
        max-width: unset;
        z-index: 1;
        width: 96%;

        & .siu-banner-image {
          left: 23%;
          height: 100%;
        }
        & .wedge-my-content {
          top: 0px;
          left: 0px;
          height: 100%;
        }
        & .wedge-open-button {
          display: none;
        }
        & .wedge-go-button {
          display: block;
        }
        & .wedge-home-button {
          display: block;
        }
      }

      & > img {
        z-index: -1;
        position: relative;
        width: unset;
      }

      & > img.wedge-open-button {
        position: absolute;
        top: 0px;
        left: 0px;
        height: 100%;
      }

      & > img.wedge-go-button {
        display: none;
        position: absolute;
        top: 0px;
        left: 0px;
        height: 100%;
      }

      & > .wedge-home-button {
        display: none;
        position: absolute;
        bottom: 5px;
        right: 1px;
        height: 50px;
        width: 50px;
      }

      & > .wedge-my-content {
        /*position: absolute;
        top: 0px;
        left: 0px;
        height: 100%;
        z-index: 1;*/
        z-index: -1;
        position: absolute;
        width: unset;
      }
    }

    &.banner-size-home-launcher {
      width: 170px;
      bottom: 109px;
      left: 22px;

      & > img {
        z-index: -1;
        position: relative;
      }

      & > img.home-launcher-reverse-banner-image {
        width: 100%;
        height: 100%;
        left: 99%;
        position: absolute;
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
      }

    }

  }
}

.device {
  &.desktop-default {
    margin: 0px auto;
    &.debug {
      margin-top: 25px;
    }
  }

  &.mobilephone-smartphone-16-9 {
    background: url("../devices/1920px/phone_16x9.png") no-repeat;
    padding: 2.0% 7.5% 0px 9.2%;
  }

  &.mobilephone-smartphone-9-16 {
    background: url("../devices/1920px/phone_9x16.png") no-repeat;
    padding: 3.8% 31.1% 0px 31.1%;
  }

  &.mobiletablet-tablet-4-3 {
    background: url("../devices/1920px/tablet_4x3.png") no-repeat;
    padding: 1.8% 15.1% 0px 16.4%;
  }

  &.mobiletablet-tablet-3-4 {
    background: url("../devices/400px/tablet_3x4.png") no-repeat;
    padding: 3.4% 27.8% 0px 27.8%;
  }

  &.mobiletablet-tablet-16-10 {
    background: url("../devices/400px/tablet_16x10.png") no-repeat;
    padding: 2.1% 10.4% 0px 12.4%;
  }

  &.mobiletablet-tablet-10-16 {
    background: url("../devices/400px/tablet_10x16.png") no-repeat;
    padding: 2.5% 29.3% 0px 29.5%;
  }

  &.ctv-default {
    background: url("../devices/1920px/ctv_16x9.png") no-repeat;
    padding: 1.25% 13.4% 0px 13.4%;
  }

  &.device.u6hero-default {
    background: url("../images/vidaa-bg.png") no-repeat, url("../devices/1920px/ctv_16x9.png") no-repeat;
    background-size: 67% 76%, contain;
    background-position: center 9%, center top;
    padding: 1.25% 13.4% 0px 13.4%;
    color: #333333;
  }

  &.device.tcl-default {
    background: url("./../images/tcl_16x9_Banner.png") no-repeat;
    background-size: 71% 100%, contain;
    background-position: center 11%, center top;
    padding: 1.25% 13.4% 0px 13.4%;
    color: #FFFFFF;
    
    .remote-text {
      position: absolute;
      bottom: -3%;
      text-align: center;
      font-size: 18pt;
      color: #000000;
      width: 66%;
    }
  }

    &.device.samsungExtraWide-default {
      background: url("../images/ctv_16x9_SamsungOpt2_LauncherFirstScreenEW.png") no-repeat;
    }

    &.device.samsungFirstScreen-default {
      background: url("../images/ctv_16x9_SamsungOpt1_LauncherFirstScreen.png") no-repeat;
    }

  &.device.samsungExtraWide-default, &.device.samsungFirstScreen-default {
    background-size: 71% 100%, contain;
    background-position: center 11%, center top;
    padding: 1.25% 13.4% 0px 13.4%;
    color: #FFFFFF;

    .remote-text {
      position: absolute;
      bottom: -3%;
      text-align: center;
      font-size: 18pt;
      color: #000000;
      width: 66%;
    }
  }

  &.easter-louvre {
    background: url("../Device_Louvre_16x9.png") no-repeat;
    padding: 7.9% 18.0% 0px 18.1%;
  }

  &.inarticlemobilephone-smartphone-9-16 {
    background: url("../devices/1920px/nativeAdMobile_9x16.png") no-repeat;
    //padding: 3.8% 31.1% 0px 31.1%;
  }
  &.unmissablephone-smartphone-16-9 {
    background: url("../devices/1920px/nativeAdMobile_9x16.png") no-repeat;
    //padding: 3.8% 31.1% 0px 31.1%;
  }
  &.inarticlemobilephone-smartphone-16-9 {
    background: url("../devices/1920px/nativeAdMobile_9x16.png") no-repeat;
    //padding: 3.8% 31.1% 0px 31.1%;
  }
  &.verticalmobilephone-smartphone-16-9 {
    background: url("../devices/1920px/nativeAdMobile_9x16.png") no-repeat;
    //padding: 3.8% 31.1% 0px 31.1%;
  }
  &.inarticlemobiletablet-tablet{
    background: url("../devices/1920px/nativeAdTablet.png") no-repeat;
    //padding: 2.5% 29.3% 0px 29.5%;
  }
  &.unmissablephone-smartphone-9-16{
    background: url("../devices/1920px/nativeAdMobile_9x16.png") no-repeat;
    //padding: 2.5% 29.3% 0px 29.5%;
  }
  &.unmissablemobiletablet-tablet{
    background: url("../devices/1920px/nativeAdTablet.png") no-repeat;
    //padding: 2.5% 29.3% 0px 29.5%;
  }
  &.verticalmobilephone-smartphone-9-16{
    background: url("../devices/1920px/nativeAdMobile_9x16.png") no-repeat;
    //padding: 2.5% 29.3% 0px 29.5%;
  }
  &.verticalmobiletablet-tablet{
    background: url("../devices/1920px/nativeAdTablet.png") no-repeat;
    //padding: 2.5% 29.3% 0px 29.5%;
  }

  &.mobilephone-smartphone-16-9,
  &.mobilephone-smartphone-9-16,
  &.mobiletablet-tablet-4-3,
  &.mobiletablet-tablet-3-4,
  &.mobiletablet-tablet-16-10,
  &.mobiletablet-tablet-10-16,
  &.ctv-default,
  &.u6hero-default,
  &.tcl-default,
  &.samsungFirstScreen-default,
  &.samsungExtraWide-default,
  &.easter-louvre {
    display: block;
    margin: auto;
    left: 0px;
    right: 0px;
    filter: drop-shadow(0px 1px 7px #aaa);
    width: 80%;
    height: 100%;
    background-size: contain;
    background-position: center;
    position: absolute;
  }

  &.inarticlemobilephone-smartphone-9-16,
  &.inarticlemobilephone-smartphone-16-9,
  &.inarticlemobiletablet-tablet,
  &.unmissablephone-smartphone-9-16,
  &.unmissablephone-smartphone-16-9,
  &.unmissablemobiletablet-tablet,
  &.verticalmobilephone-smartphone-16-9,
  &.verticalmobilephone-smartphone-9-16,
  &.verticalmobiletablet-tablet{
    position: absolute;
    display: block;
    margin: auto;
    left: 0px;
    right: 0px;
    width: 100%;
    height: 100%;
    background-position: center;
  }

  &.inarticlemobilephone-smartphone-9-16,
  &.inarticlemobilephone-smartphone-16-9,
  &.unmissablephone-smartphone-9-16,
  &.unmissablephone-smartphone-16-9,
  &.verticalmobilephone-smartphone-16-9,
  &.verticalmobilephone-smartphone-9-16 {
    background-size: 372px 670px;
  }

  &.inarticlemobiletablet-tablet,
  &.unmissablemobiletablet-tablet,
  &.verticalmobiletablet-tablet {
    background-size: 480px 650px;
  }
}

.adTypeDisplay #panel-main,
.adTypeAudio #panel-main {
  margin: 0px;
}
.displayAdMain .displayAdContainer,
.audioAdMain .audioAdContainer {
  margin-top: 131px;
  text-align: center;
}
.displayAdMain .displayAdContainer iframe.displayIframe {
  border: none;
  outline: none;
}
.displayAdMain .displayAdContainer iframe.displayIframe body {
  margin: 0px;
}
.displayAdMain .displayAdContainer .displayError {
  margin: 0px;
}
.displayAdError {
  height: 50px;
}

.adTypeVidaa, .wrapper-tcl-default, .wrapper-samsungExtraWide-default, .wrapper-samsungFirstScreen-default {
  .closeScreenInstruction {
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    color: #000;
  }

  .samsung-player {
    width: 100.2%;
    height: 77%;
    position: relative;
    left: -.15%;

    .player {
      width:100%;
      height: 100%;
      video {
        position: relative;
        z-index:10001;
        width:100%;
      }

      .player-under {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: black;
      }
    }

    .samsung-overlay-endcard {
      visibility: hidden;
      position: absolute;
      float: left;
      z-index: 10002;
      width: 70%;
      height: 40%;
      transform: translate(-50%, -50%);
      left: 50%;
      top: 50%;
      z-index:10003;

      .background-endcard {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: black;
        opacity: .6;
      }

      .launcherTile-endcard {
        position: relative;
        float: left;
        transform: translateY(-50%);
        top: 50%;
        height: 85%;
        left: 2%;

        img {
          position: relative;
          left: 0;
          top: 0;
          height:100%;
        }
      }

      .text-container-endcard {
        float: left;
        position: relative;
        left: 4%;
        top: 4%;
        height:88.5%;

        .advertTitle-endcard {
          position: relative;
          font-size: 1.4vw;
          font-weight: bold;
        }

        .advertSubtitle-endcard {
          position: relative;
          font-size: 0.8vw;
          font-weight: bold;
        }

        .replayButton {
          border-radius: 3px;
          border: .18em solid white;
          position: absolute;
          bottom: 0;
          padding: 7% 36%;
          font-size: 0.85vw;
          cursor: pointer;
        }
      }
    }

    .samsung-overlay-header {
      visibility: hidden;
      position: absolute;
      float: left;
      z-index: 10002;
      width: 100%;
      height: 13.6%;
      background: url("../images/02OverlayBackground-small.png") repeat-x;
      background-size: 111% auto;

      .launcherTile {
        position: relative;
        transform: translateY(-50%);
        top: 50%;
        height: 50%;
        left: 2%;
        float: left;

        img {
          position: relative;
          left: 0;
          top: 0;
          height:100%;
        }
      }     

      .text-container-header {
        float: left;
        position: relative;
        left: 3%;
        top: 17%;
        height:53.5%;

        .advertTitle-header {
          position: relative;
          font-size: 1.05vw;
          font-weight: bold;
        }

        .advertSubtitle-header {
          position: relative;
          font-size: 0.65vw;
          font-weight: bold;
        }
      }
    }
  }

  .outstream-container {
    position: relative;
    height: auto;
    margin: 0px;
    z-index: 1; 

    .vidda-insert-position, .samsung-insert-position {
      position: absolute;
      top: 0px;
      left: 0px;
      right: 0px;

      #av-caption {
        display: none;
      }
    }
  }
  .vidaaAdMain {
    position: relative;
    text-align: center;
    z-index: 0;

    .samsungCTAText {
      position: absolute;
      color: white;
      font-size: 0.8vw;
      margin: -2.1% 0 0 1.8%;
    }

    .vidaaAdContainer {
      width: 90%;
      margin: 9% auto 0px auto;
      overflow: hidden;
      
        &.tcl-banner {
            width: 21.8%;
            margin: 34.8% 0px 0px 4.9%;
            aspect-ratio: 16 / 9;
            padding: 2px;
            border: 3px solid greenyellow;
            box-sizing: content-box;
        }

        &.samsungFirstScreen {
          width: 21.2%;
          margin: 31.3% 0px 0px 1.7%;
          aspect-ratio: 16 / 9;
        }

        &.samsungExtraWide {
          width: 68.7%;
          margin: 31.3% 0px 0px 1.6%;
          aspect-ratio: 1840 / 320;
        }

        &.samsungFirstScreen-launcher {
          width: 10.3%;
          margin: 1% 0 0 12.3%;
          aspect-ratio: 16 / 9;
        }

        &.samsungExtraWide-launcher {
          width: 13.9%;
          margin: 0.05% 0 0 12.7%;
          aspect-ratio: 16 / 9;
        }

      .vidaaAdError {
        position: relative;
        overflow: hidden;

        &.tcl-error, &.samsung-error {
          background-color: #343434;
          opacity: 0.85;
        }

        .vidaaAdErrorBg {
          position: static;
          z-index: 1;
        }
        .vidaaAdErrorTxt {
          position: absolute;
          top: 50%;
          bottom: 0px;
          left: 10%;
          right: 10%;
          transform: translateY(-50%);
          z-index: 2;
        }
      }

      .vidaaAd {
        position: relative;

        .vidaaAdBannerImg {
          position: static;
          opacity: 0;
          transition: opacity 0.4s ease-in;
          z-index: 0;
        }

        .vidaaAdBannerImg.loaded {
          opacity: 1;
        }

        .vidaaAdBannerImg.removing {
          opacity: 0;
          transition: none; /* Disable the transition when removing */
        }
      }

      img {
        width: 100%
      }
    }
  }
  .vidaaRemote {
    display: none;
    position: absolute;
    top: 9%;
    right: -3%;
    width: 15%;
    height: 65%;
    background: url("../images/remote/vidaaRemote.png") center top / contain no-repeat;

    &.remotePrevHover {
      background:  url("../images/remote/left-hover.png") center top / contain no-repeat, url("../images/remote/vidaaRemote.png") center top / contain no-repeat;
      &.remotePrevPressed {
        background:  url("../images/remote/left-active.png") center top / contain no-repeat, url("../images/remote/vidaaRemote.png") center top / contain no-repeat;
      }
    }
    &.remoteOkHover {
      background: url("../images/remote/ok-hover.png") center top / contain no-repeat, url("../images/remote/vidaaRemote.png") center top / contain no-repeat;
      &.remoteOkPressed {
        background:  url("../images/remote/ok-active.png") center top / contain no-repeat, url("../images/remote/vidaaRemote.png") center top / contain no-repeat;
      }
    }
    &.remoteNextHover {
      background: url("../images/remote/right-hover.png") center top / contain no-repeat, url("../images/remote/vidaaRemote.png") center top / contain no-repeat;
      &.remoteNextPressed {
        background:  url("../images/remote/right-active.png") center top / contain no-repeat, url("../images/remote/vidaaRemote.png") center top / contain no-repeat;
      }
    }
    &.remoteBackHover {
      background: url("../images/remote/back-hover.png") center top / contain no-repeat, url("../images/remote/vidaaRemote.png") center top / contain no-repeat;
      &.remoteBackPressed {
        background:  url("../images/remote/back-active.png") center top / contain no-repeat, url("../images/remote/vidaaRemote.png") center top / contain no-repeat;
      }
    }

    .remoteRow {
      display: flex;
      flex-direction: row;
      align-items: flex-start;

      &.two {
        height: 13%;
        margin: 45% 17% 0;
      }

      &.tcl-ok, &.samsung-ok {
        height: 13%;
        margin: 43.5% 34.5% 0;
      }

      .remoteBtnBack {
        position: absolute;
        top: 47%;
        height: 9%;
        width: 32%;
        cursor: pointer;
      }

      .remoteBtnPrev,
      .remoteBtnOkay,
      .remoteBtnNext {
        flex: 1;
        height: 100%;
        cursor: pointer;
      }
    }
  }

  .vidaaRemote.show {
    display: block;
  }

  .vidaaShowHideRemote {
    position: absolute;
    top: -25px;
    right: 16%;
    height: 30px;
    text-align: right;
    color: #666666;
    text-decoration: underline;
  }
  .vidaaShowHideRemote:hover {
    cursor: pointer;
  }

  .screenPopup {
    position: absolute;
    top: 2.25%;
    left: 16.5%;
    right: 16.5%;
    z-index: 3;

    img {
      width: 107%;
      margin-left: -3.5%;
      margin-top: -2%;
      &::selection {
        background: transparent;
      }
    }

    .samsung-video-overlay {
      position: absolute;
      top: 2.25%;
      left: 16.5%;
      right: 16.5%;
      z-index: 3;
    }

    img[src*='screen-youtube'], img[src*='screen-playStore'] {
      width: 100%;
      margin: 0.5% 0 0 0;
    }
  }

  .dotNav {
    position: absolute;
    bottom: 2%;
    left: 0%;
    right: 0%;
    //left: 50%;
    //transform: translateX(-50%);
    z-index: 1;

    .dotNavTable{
      width: 100%;
      height: 100%;
      display: table;

      .dotNavCell{
        display: table-cell;
        vertical-align: middle;
        margin: auto;
        text-align: center;

        .dots{
          position: relative;
          display: inline-block;
          margin: 0;
          padding: 0;
          list-style: none;
          cursor: default;

          .active{
            span{
              -webkit-transform: scale3d(1.3, 1.3, 1.3);
              transform: scale3d(1.3, 1.3, 1.3);

              &:after{
                height: 100%;
              }
            }
          }

          li{
            position: relative;
            display: block;
            float: left;
            margin: 0 5px;
            width: 5px;
            height: 5px;
            cursor: pointer;

            span{
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              outline: none;
              border-radius: 50%;
              text-indent: -999em;
              cursor: pointer;
              position: absolute;
              overflow: hidden;
              background-color: transparent;
              box-shadow: inset 0 0 0 2px rgba(255,255,255, 0.4);
              -webkit-transition: all 0.3s ease;
              transition: all 0.3s ease;
              -webkit-transform: scale3d(1, 1, 1);
              transform: scale3d(1, 1, 1);

              &:after{
                content: '';
                position: absolute;
                bottom: 0;
                height: 0;
                left: 0;
                width: 100%;
                background-color: #fff;
                box-shadow: 0 0 1px #fff;
                -webkit-transition: height 0.3s ease;
                transition: height 0.3s ease;
              }
            }
          }
        }
      }
    }
  }
}

//html, body{
//  margin: 0px;
//  padding: 0px;
//  font-family: 'Lato',sans-serif;
//  font-size: 18px;
//  font-weight: 300;
//  height: 100%;
//  color: #fff;
//  background: #fbfbfb;
//  box-sizing: border-box;
//  -moz-box-sizing: border-box;
//  transition: all 0.3s ease-in-out;
//}



