$footer-height: 140px;
$main-background-color: #FFFFFF;
$advanced-panel-background-color:#F5F5F5;
$main-font-color:#212121;
$main-border-color: #ffffff;
$dropdown-font-color: #15003B;
$dropdown-icon-color: #15003B;
$button-trmr-button-border-color:#70707066;
$button-trmr-default-color:#C7CAD3;
$button-trmr-client-link-color:#F5F5F5;
$field-background-color: #FFFFFF;
$button-hover-background-color: #404C71;
$paragraph-background-color: #F2F2F2;
$button-hover-font-color: #ffffff;
$element-height: 56px;
//$element-border-radius: 0.5px; // circle
$element-border-radius: 28px; // circle
$element-border-color:  #70707065;
$main-blue: #15003B;
$default-font-family:'Arial';

@font-face {
  font-family: 'Gotham';
  src: url('../fonts/Gotham-Medium.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham';
  src: url('../fonts/Gotham-Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}

body, html {
  height: 100%;
}

body {
  background-color: $main-background-color;
  font-family: $default-font-family;
  font-weight: bold;
  font-weight: 400;
  //this is a quick fix for the issue with some screens that the scroll flicks each seconds while playing an ad on outstream
  //this issue should re-investigate , we have seen when we remove the progress bar of the native ad the issue doesnt not appear anymore
  //for now we always show the vertical scroll bar to prevent this issue reappears
  overflow-y: scroll;
}

// Removes annoying dashed line around select element on FF
select:-moz-focusring {
  color: transparent !important;
  text-shadow: 0 0 0 $main-blue !important;
}

// Removes annoying white box behind select element on Edge
select:focus::-ms-value {
  background: transparent !important;
  color: $main-blue !important;
}

// Used for horizontal collapsing/expanding
.width {
  -webkit-transition: width 0.2s ease;
  -moz-transition: width 0.2s ease;
  -o-transition: width 0.2s ease;
  transition: width 0.2s ease;
}

.loader {
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: 0px;
  text-align: center;
  z-index: 1000;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

.loader.active {
  display: block;
}

.large.loader {
  width: 3.71428571rem;
  height: 3.71428571rem;
  font-size: 1.28571429em;
  &.adjust {
    margin-top: 17%;
  }
}

/* Static Shape */
.loader:before {
  position: absolute;
  content: '';
  top: 0%;
  left: calc(50% - 50%);
  width: 100%;
  height: 100%;
  border-radius: 500rem;
  border: 0.2em solid rgba(255, 255, 255, 0.2);
}

/* Active Shape */
.loader:after {
  position: absolute;
  content: '';
  top: 0%;
  left: calc(50% - 50%);
  width: 100%;
  height: 100%;
  -webkit-animation: loader 0.6s linear;
  animation: loader 0.6s linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  border-radius: 500rem;
  //border-color: #767676 transparent transparent;
  border-color: #e9e9e9 transparent transparent;
  border-style: solid;
  border-width: 0.2em;
  -webkit-box-shadow: 0px 0px 0px 1px transparent;
  box-shadow: 0px 0px 0px 1px transparent;
}

/* Active Animation */
@-webkit-keyframes loader {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.btn-info.focus,
.btn-info:focus,
.tmr-nav-btn.focus,
.tmr-nav-btn:focus {
  box-shadow: 0px 0px 0px 0px rgba(0,0,0,0);
}

.btn-info {
  &:disabled {
    background-color: $button-trmr-default-color;
    border-color: $button-trmr-button-border-color;
    cursor: default;
    &:hover {
      background-color: $button-trmr-default-color;
    }
  }
  &.tmr-nav-btn,
  &.btn-siu-video-apply {
    &.disabled {
      background-color: $button-trmr-default-color;
      border-color: $button-trmr-button-border-color;
      cursor: default;
      &:hover {
        background-color: $button-trmr-default-color;
      }
    }
  }
}


.inarticle-unmissable-container{
  height: 100%;
}

.label-addon {
  background-color: transparent;
  border: none;
}
.inarticle-title{
  line-height: 300px;
}
.roundedBorder {
  background:#15003B;
  border: 1px solid #15003B;
  border-radius: $element-border-radius !important;
  border-top-left-radius: $element-border-radius !important;
  border-bottom-left-radius: $element-border-radius !important;
  border-top-right-radius: $element-border-radius !important;
  border-bottom-right-radius: $element-border-radius !important;
  border-radius: $element-border-radius !important;
}

.app-logo{
  background:url('../images/blacklogo.png') 0% 0% no-repeat padding-box;
  width:55px;
  height:70px;
  background-size: 56px;

}
.branding-txt{
  border-left: 1px solid #F2F2F2;
  height:45px;
  width:100%;
  display: inline;
  line-height: 45px;
  padding-left: 15px;
  color:$main-font-color;
  font-weight: bold;
}
.default-input-styles {
  background-color: $field-background-color !important;
  color: $main-font-color !important;
  height: $element-height;
  padding: 0px $element-border-radius;
  border: 0px;

  &:focus {
    background-color: $field-background-color;
    color: $main-font-color;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    box-shadow: 0 0 0 30px $field-background-color inset !important;
    -webkit-box-shadow: 0 0 0 30px $field-background-color inset !important;
    -webkit-text-fill-color: $main-font-color !important;
  }
}

.default-dropdown-styles {
  background-color: $field-background-color;
  color: $dropdown-font-color;
  border: 0px;
  padding: 0px;
  appearance: none;
  margin: 0px !important;
  height: $element-height !important;

  &:disabled {
    background-color: $field-background-color;
    opacity: 0.4;
  }
  &:focus {
    background-color: $field-background-color;
    color: $dropdown-font-color;
  }

  option {
    background-color: $field-background-color;
    color: $dropdown-font-color;
  }

  optgroup[label="TCL"] {
    display: none;

    option[value="tcl"] {
      display: none;
    }
  }

  optgroup[label="Samsung Extra Wide"] {
    display: none;

    option[value="samsungExtraWide"] {
      display: none;
    }
  }

  optgroup[label="Samsung First Screen"] {
    display: none;

    option[value="samsungExtraWide"] {
      display: none;
    }
  }

  optgroup[label="VIDAA"] {
    display: none;

    option[value="u6hero"] {
      display: none;
    }
  }

  // &--40px {
  //   height: 40px !important;
  // }
}

.dropdown-wrapper {
  padding: 0px $element-border-radius;
  background-color: $field-background-color;
  position: relative;
  //padding: 0px 25px;
  //background-color: $field-background-color;
  //border: $element-border-radius solid $element-border-color;
  //position: relative;
}

.dropdown-icon-container {
  position: absolute;
  // top: 5px;
  z-index: 19;
  right: 25px;
  pointer-events: none;
  color: $dropdown-icon-color;
  line-height: 0px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.apply-btn, .btn-rml {
  width: 100%;
  cursor: pointer;
}

.main-body {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  background:$main-background-color;
}

.jw-advanced-panel {
  z-index: 10;
  background-color: white;
  margin-left: auto;
  margin-right: auto;
  min-height: 0px;
  color: $main-font-color;
}

.badge-tvce, .badge-misc {
  background: $main-blue; // bright-sea-green
}
.badge-vpaid, .badge-rml {
  background: #e03cff;
}
.badge-jw, .badge-bc {
  background: #fbca01; // marigold
}
.badge-primary.badge-native{
  background: #fbca01; }
.badge-primary.badge-rx{
  background: #ff3c3d; }

.nav-item {
  .selected {
    background: #0e5d69;
    color: #818181;
    border: none;
    outline-style: none;
  }

  :active, :focus {
    outline-style: none !important;
  }
}

.tmr-advertisername {
  color: white;
}

.btn-info {
  border: none;
  background-color: $main-blue;
  color: #FFFFFF;
  height: $element-height;
  line-height: 0px;

  &:hover, &.selected {
    background-color: $button-hover-background-color;
    color: $button-hover-font-color;
  }
}

.svg-icon {
  transition: transform 0.2s ease;
  transform: scaleY(1);

  &.flip {
    transform: scaleY(-1);
  }
}

.tmr-nav-btn {
  background-color: $button-trmr-default-color;
  color: white;
  height: 40px;
  margin-right:17px;
  cursor: pointer;

  &:hover, &.selected {
    background-color:$main-blue;
  }

  .svg-icon {
    display: none;
  }

  .tmr-nav-btn-text {
    text-align: left;
    padding-left: 16px;
  }

  @media (min-width: 768px) {
    padding: 0px;
    line-height: 0;
    background-color: $button-trmr-default-color;
    color: white;

    &:hover, &.selected {
      background-color: $main-blue;

    }

    .svg-icon {
      display: inline;
    }

    .tmr-nav-btn-text {
      display: none;
    }
  }
}
.btn-info.tmr-client-link-btn {
  font-size: 1rem;
  font-family: "Arial MT Bold",Arial;
  background-color: $advanced-panel-background-color;
  color: #434343;
  width:100%;
  padding: 10px;
  cursor: pointer;
  border: 0.5px solid $button-trmr-button-border-color;
  text-align: center;
  &:hover{
    &:not([disabled]) {
      background-color: $main-blue;
      &.selected {
        filter: none;
      }

      filter: brightness(0.9);
    }
  }
  &:disabled {
    background-color: $field-background-color;
    opacity: 0.4;
    border-color: $button-trmr-button-border-color;
    cursor: default;
  }
  .svg-icon {
    display: none;
  }
  .tmr-nav-btn-text {
    text-align: left;
  }
  @media (min-width: 768px) {
    line-height: 0;
    background-color: $button-trmr-client-link-color;
    color: #434343;
    &:hover, &.selected {
      &:not([disabled]) {
        background-color: $main-blue;
        color: #FFFFFF;
      }
      &[disabled]{
        color: #434343;
      }
    }
    .svg-icon {
      display: inline;
    }
    .tmr-nav-btn-text {
      display: none;
    }
  }
}

#log-events {
  position: relative;
  max-height: 50vh;
  // height: calc(100vh - 150px);
  top: 0px;
  bottom: 0px;
  padding: 0px 70px;
  margin: 45px 0px;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 4px;
    background: transparent; /* make scrollbar transparent */
  }

  &::-webkit-scrollbar-thumb {
    background: $main-blue;
    border-radius: 2px;
  }

  @media (min-width: 768px) {
    position: absolute;
    max-height: none;
  }
}
/*
.tmr-nav {
  // background: rgb(75,82,88); /* Old browsers */
  // background: -moz-linear-gradient(top, rgba(75,82,88,1) 0%, rgba(46,53,59,1) 100%); /* FF3.6-15 */
  // background: -webkit-linear-gradient(top, rgba(75,82,88,1) 0%,rgba(46,53,59,1) 100%); /* Chrome10-25,Safari5.1-6 */
  // background: linear-gradient(to bottom, rgba(75,82,88,1) 0%,rgba(46,53,59,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  // filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4b5258', endColorstr='#2e353b',GradientType=0 ); /* IE6-9 */
  // max-width: 1024px;
/*}*/

.tmr-navbar-collapse {
  flex-direction: row;
  justify-content: space-between;
}

.container-fluid {
  width: auto;
  margin-right: 35px;
  margin-left: 35px;
}

.tmr-nav-background-fill {
  background: $main-background-color;
  box-shadow: 0px 5px 6px #0000000A;
}

.tmr-brand {
  display: block;
  margin-right: 15px;
  margin-bottom: 9px;
  //margin-top: 21px;
  color: white;
  //// background: url('../images/Tremor_OneLine_White_Black_Bg.png') no-repeat;
  //display: block;
  //width: 225px;
  ////height: 35px;
  //
  //float: left;
  //margin-right: 15px;
  //margin-bottom: 16px;
  //margin-top: 17px;
  //color: white;

}

.logo_svg .logo_embeded_image{
  overflow:visible;
  opacity:1;
}

.logo-tri-1,.logo-tri-2,.logo-tri-3,.logo-tri-4,.logo-tri-5,.logo-tri-6 { transition: fill .2s ease; }
.logo_svg .logo-text{fill:#FFFFFF;}
.logo_svg .logo-tri-1{fill:#BBB;}
.logo_svg .logo-tri-2{fill:#FFFFFF;}
.logo_svg .logo-tri-3{fill:#BBB;}
.logo_svg .logo-tri-4{fill:#FFFFFF;}
.logo_svg .logo-tri-5{fill:#BBB;}
.logo_svg .logo-tri-6{fill:#FFFFFF;}
.logo_svg:hover .logo-tri-1 {fill:url(#SVGID_1_);}
.logo_svg:hover .logo-tri-2 {fill:#F05822;}
.logo_svg:hover .logo-tri-3 {fill:url(#SVGID_2_);}
.logo_svg:hover .logo-tri-4 {fill:#F27321;}
.logo_svg:hover .logo-tri-5 {fill:url(#SVGID_3_);}
.logo_svg:hover .logo-tri-6 {fill:#F58929;}

.logo_svg:hover .logo_embeded_image{
  opacity:0.15;
}

.tmr-nav-toggler {
  float: right;
  display: block;
}

.nav-item {
  label {
    display: block;
    font-size: 14px;
    color: white;
    text-align: center !important;
    margin: 0px;
  }
}

#panel-main {
  margin-top: 45px;
  margin-bottom: 45px;
}

#root {
  height: 100%;
  width: 100%;
  position: inherit;
  .App {
    height: 100%;
    width: 100%;
    position: inherit;
  }
  .sidebar-toggler {
    display: block;
    margin-right: .75em;
  }

  .footer {
    min-height: $footer-height;
    // line-height: $footer-height / 2.5;
    line-height: 3;
    width: 100%;
    background-color: $main-background-color;
    bottom: 0px;
    color: #818181;
    font-size: 0.75rem;
    //border-top: 4px solid black;

    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;

    .footer-text {
      padding: 0px 15px;
    }
  }
}

.footer-link, .footer-link:link, .footer-link:hover, .footer-link:active, .footer-link:visited {
  color: #15003B;
  cursor: pointer;
  opacity: 1;
}

.video-js {
  video {
    width: 100%;
  }
  .vjs-ima3-ad-container > div, .vjs-ima3-ad-container iframe {
    width: 100% !important;
    height: 100% !important;
  }
}

.tmr-btn {
  background-color: #00ABBD !important;
  border: 0px solid #ffffff;
}

.vastURL, .vastURL form input {
  width: 100%;
}

.tmr-float-left {
  float: left;
  margin: 0px;
  padding: 0px;
}

.card-body {
  padding: 0px 11px;

  form {
    padding: 0px 15px;
  }
}

.tmr-center {
  margin: 0px auto;
}
.siu-click-thru-cover-message{
  width: 100%;
  height: 100%;
  background-color: #474747;
  color:#FFFFFF;
}
.siu-click-thru-cover-message .message{
  margin: 0 auto;
  text-align: center;
  /* margin-top: 40%; */
  padding-top: 25%;
  font-size: 25px;
  font-weight: bold;
  color: #FFFFFF;
}
.JWAdvancePanel-card {
  //border-left: 4px solid black;
  //border-right: 4px solid black;
  //border-bottom: 4px solid black;
  border-top: 0px;
  background-color: $advanced-panel-background-color;
  border-radius: 0;

  &.AdvancePanel {
    padding-top: 50px;
    padding-bottom: 15px;

    .AdvancePanel-label {
      padding: 0px 0px 12px $element-border-radius;
      font-weight: 700;
    }
  }
}

.JWAdvancePanel-collapse {
  .DCOPanel {
    span {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0px 0px 0px 28px;
    }
    .col {
      margin-bottom: 0;
    }
    .row {
      padding: 0px 0px 28px 0px;
      margin: 0px;
    }
    .DCOHeader {
      margin-top: 28px;
      .DCOTitle {
        font-weight: 700;
      }
    }
    button {
      text-align: center;
      float: right;
      width: $element-height;
    }
    .lightGray {
      color: #bbb;
    }
  }
}

#panel-debug-wrapper {
  width: 100%;
  background-color: $advanced-panel-background-color;
  //border-left: 4px solid black;
  //border-right: 4px solid black;
  position: relative;
}

#panel-debug {
  width: 100%;
  position: relative;
  height: auto;

  ul {
    li {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 18px 0px;
      font-size: 14px;
      line-height: 1.43;
      color:#434343;
      font-weight: bold;
      word-break: break-all;
      .badge {
        height: 42px;
        width: 42px;
        text-align: center;
        margin: auto;
        font-size: 12px;
        padding: 0px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        color: #FFFFFF;
      }
    }
  }
}

.tmr-device-menu {
  border-bottom: 1px solid rgba(0,0,0,.125);
}

.companion-panel-container {
  width: 100%;
  margin-top: 45px;
  margin-bottom: 45px;
}

.companion-panel {
  max-width: 300px;
  margin: 0px auto;
}

#companion-300-250 {
  background-color: $paragraph-background-color;
  height: 250px;
  width: 300px;
  margin: 0px auto 0px;
}

.wireframe {
  background-color: $paragraph-background-color;
}

.fake-companion-content {
  display: none;
}

.fake-paragraph-content {
  display: none;
  margin-left: 66px;
  margin-right: 0px;
}

@mixin warning-message{
  text-align: center;
  color:$main-blue;
}

.screen-size-warning{
  display: none;
  margin-top:50px;
  @include warning-message;
}
.safari-not-supported{
  margin-top:50px;
  @include warning-message;
}

.qrcode {
  display: block;
  margin: 67px auto 0px auto;
  height: 204px;
  width: 204px;
}

.navbar {
  padding: 0px 15px;
}

/* Media Queries */

// Extra-Small devices (portrait phones, 575px and below)
@media (max-width: 575px) {
  .tmr-nav {
    display: block;
    // background-color: red;
  }

  .vastURL input {
    width: 100%;
  }

  .brand-info {
    list-style: none;
    border-left: 0px solid white;
    //padding-left: 28px;
    padding-bottom: 15px;
    font-size: 16px;
    color: black;
    font-family: Arial;
    font-weight: 700;
  }

  .wireframe-bottom {
    display: none;
  }

  .nav-item {
    .btn {
      width: 100%;
      text-align: center;
      margin-bottom: 15px
    }

    select {
      width: 100%;
      margin-top: 5px;
    }
  }

  .card-body form .col-xs-12 {
    margin-bottom: 1rem;
  }

}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
  .tmr-nav {
    display: block;
    // background-color: purple;
  }

  .brand-info {
    list-style: none;
    border-left: 0px solid white;
    padding-left: 28px;
    padding-bottom: 15px;
    font-size: 16px;
    font-family: Arial;
    font-weight: 700;
    color: black;
  }

  .vastURL input {
    width: 100%;
  }

  .wireframe-bottom {
    display: none;
  }

  .nav-item {
    .btn {
      width: 100%;
      text-align: center;
      margin-bottom: 15px;
    }

    select {
      width: 100%;
      margin-top: 5px;
    }
  }


  .card-body form .col-xs-12 {
    margin-bottom: 34px;
  }


  .companion-panel {
    margin-left: 66px;
    margin-right: 66px;
  }

  .fake-companion-content {
    display: block;
  }

}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .tmr-nav {
    display: block;
    // background-color: blue;
    padding: 0px 15px 0px 15px;
  }

  .brand-info {
    list-style: none;
    border-left: 1px solid white;
    padding-left: 15px;
    padding-bottom: 0px;
    font-size: 16px;
    font-family: Arial;
    font-weight: 700;
    color: black;
  }

  .wireframe-bottom {
    display: none;
  }

  .nav-item .btn {
    height: 46px;
    width: 46px;
    border-radius: 50%;
    margin-top: 14px;
    margin-bottom: 14px;
  }

  .nav-item select {
    width: 200px !important;
  }

  .nav-item {
    .form-group {
      margin-top: 14px;
      margin-bottom: 14px;
    }
  }

  #panel-debug-wrapper {
    width: auto;
  }

  #panel-debug {
    width: 344px;
    height: 100%;
  }

  .fake-paragraph-content {
    display: block;
  }

}

@media (max-width: 1200px) {
  .outstream-container {
    h1.text-format.inarticle-title {
      font-size: 2.0rem
    }
  }
}
@media (max-width: 992px) {
  .outstream-container {
    h1.text-format.inarticle-title {
      font-size: 1.5rem
    }
  }
}

@media (max-width: 840px) {
  .outstream-container {
    h1.text-format.inarticle-title {
      font-size: 1.2rem
    }
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  .tmr-nav {
    display: block;
  }

  .wireframe-bottom {
    display: block;
  }

  .companion-panel-container {
    width: auto;
    margin-right: 66px;
  }

  .companion-panel {
    margin: 0px;
    width: 300px;
  }

  .fake-paragraph-content {
    display: none;
  }
}
.outstream-insert-position{
  position: relative;
  overflow: hidden;
}
.outstream-container{
  height: 100%;
  margin-top: 2rem;
  .text-format{
    color: #15003B;
  }

  ::-webkit-scrollbar {
    display: none;
  }

  .inarticle-mobile-container{
    width: 320px;
    height: calc(100% - 5rem);
    display: block;
    margin: 30px auto;
    content-visibility: auto;
    contain: paint;
    transform: translateZ(0);

    .inarticle-mobile-inner{
      width: 100%;
      height: 100%;
      right:0;
      display: block;
      overflow-y: auto;
      overflow-x: hidden;
      box-sizing: border-box;
      font-size: 14px;
      position: relative;
      .text-format{
        font-size: 14px;
        line-height: 100px;
      }
      #native-ad14{
        position: relative;
      }
    }
  }
  .inarticle-tablet-container{
    width: 410px;
    height: calc(100% - 5rem);
    display: block;
    margin: 20px auto;
    content-visibility: auto;
    contain: paint;
    transform: translateZ(0);

    .inarticle-tablet-inner{
      //width: 100%;
      height: 100%;
      display: block;
      overflow-y: auto;
      overflow-x: hidden;
      box-sizing: border-box;
      .text-format{
        font-size: 14px;
        line-height: 150px;
      }
    }
  }
}


// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  .tmr-nav {
    display: block;
  }

  .wireframe-bottom {
    display: block;
  }
}
//Media queries for Client Link
@media (min-width: 1024px){
  .client-link-container .grids{
    gap: 15px !important;
  }
  .tmr-client-link-btn {
    font-size: 1rem !important;
    padding: 15px !important;
  }
}
@media (min-width: 1440px){
  .tmr-client-link-btn{
    padding: 20px !important;
  }
  .client-link-container .grids{
    gap: 20px !important;
  }
  .Modal{
    padding: 30px !important;
  }
}
@media (min-width: 2560px){
  .Modal{
    padding: 40px !important;
    .close{
      font-size: 2rem !important;
          }
  }
  .client-link-container .grids{
    padding-top: 2.5rem !important;
    gap: 25px !important;
  }
  .tmr-client-link-btn{
    font-size: 1.5rem !important;
    padding: 25px !important;
  }
  .client-link-container input[type="url"]{
    font-size: 1.2rem !important;
    padding: 15px !important;
  }
  .client-link-container {
    .title{
    font-size: 2rem !important;
  } 
    label{
      font-size: 1.5rem !important;
      margin-top: 20px !important;
  }
    .btn-siu-video-apply{
font-size: 1.2rem !important;
    }
  }
}
@media (min-width: 3400px){
  .Modal{
    padding: 50px !important;
    .close{
      font-size: 2.5rem !important;
        }
  }
  .client-link-container .grids{
    padding-top: 2.5rem !important;
    gap: 30px !important;
  }
  .tmr-client-link-btn{
    font-size: 2rem !important;
    padding: 40px !important;
  }
  .client-link-container input[type="url"]{
    font-size: 2rem !important;
    padding: 15px !important;
  }
  .client-link-container {
    .title{
    font-size: 2.2rem !important;
  } 
    label{
      font-size: 2rem !important;
  }
  .btn-siu-video-apply{
      font-size: 2rem !important;
      width: 100% !important;
height: auto !important;
  }
  }
}
.Modal {
  left: 50%;
  top: 50%;
  position: fixed;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: max-content;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  padding: 20px;
  z-index: 1000;
.modal-content{
  display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: none;
    .close{
      position: absolute;
      top: 10px;
      right: 15px;
      font-size: 24px;
      color: #333;
      cursor: pointer;
    }
  }
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 20;
  backdrop-filter: blur(5px);
}
.client-link-container{
  text-align: center;
  .title{
    color:$main-blue;
    font-size: 1.5rem;
    font-family: Arial;
    margin-bottom: 10px;
    font-weight: 700;
  }
  label{
    color:#434343;
    font-size: 1rem;
  }
  .grids{
    display: grid;
    list-style: none;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    padding: 1rem 0 0 0;
    grid-auto-flow: column;
    grid-template-rows: 20% 20% 20% 20%;
  }

  ul{
    border-top:1px solid #C7CAD3;
    list-style-type: none;
    li{
      &:nth-child(13n){
        margin-right: 0;
      }
      &:nth-child(14n){
        margin-right: 0;
      }
      &:nth-child(15n){
        margin-right: 0;
      }
      &:nth-child(16n){
        margin-right: 0;
      }
    }

  }
  .client-link-url-container{
    margin-top:20px;
    display: flex;
    .input-group:nth-child(1){
      flex:2;
      height:50px;
      min-width: 934px;
    }
    .input-group:nth-child(2){
      flex:1;
      color:#434343;
      .btn-siu-video-apply{
        cursor: pointer;
        width:256px;
        height:50px;
      }
    }
  }
  @media (max-height: 768px) {
    padding: 0 23px 23px 23px;
  }
  #customUrlInput {
    max-width: 100%;
    @media screen and (min-width: 1441px) {
      min-width: 88.5% !important;
    }
  }
}


@media screen and (max-width: 1439px){
.screen-size-warning{
  display: block;
}
}
@media (max-width: 1440px) {
  .client-link-container{
    .client-link-url-container
    {
      margin-top: 20px;
      .input-group:nth-child(1){
        min-width: auto;
        max-width: 590px;
      }
      .input-group:nth-child(2){
        min-width: auto;
        max-width: 240px;
      }
      }
  }
}
/* End - Media Queries */
